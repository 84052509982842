<template>
  <div>
    <leaflet-panel
      :body="body"
      :image="image"
      :show-buy-btn="showBuyBtn"
      :show-detail-btn="showDetailBtn"
      :title="title"
    />
    <!-- <ImageList :images="images"></ImageList> -->

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
      :title="`产品优势`"
      :en_title="`Product Advantages`"
    ></Paragraph>
    <Titlestrlist :datalist="datalist1"></Titlestrlist>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
      :title="`应用场景`"
      :en_title="`Context of Use`"
    ></Paragraph>
    <Titlestrlist :datalist="datalist2.slice(0, 1)"></Titlestrlist>

    <el-row style="margin: 0 10%">
      <el-col :span="8">
        <img
          :src="images[3]"
          style="width: 100%; height: 100%; align-items: center"
        />
      </el-col>
      <el-col :span="16">
        <Titlestrlist :datalist="datalist2.slice(1, 4)"></Titlestrlist>
      </el-col>
    </el-row>

    <Titlestrlist :datalist="datalist3.slice(0, 1)"></Titlestrlist>
    <el-row style="margin-left: 3.7%;margin-right: 10%">
       <el-col :span="16">
        <Titlestrlist :datalist="datalist3.slice(1, 4)"></Titlestrlist>
      </el-col>
      <el-col :span="8">
        <img
          :src="images[4]"
          style="width: 100%; height: 100%; align-items: center"
        />
      </el-col>
    </el-row>

    <Titlestrlist :datalist="datalist4.slice(0, 1)"></Titlestrlist>

    <el-row style="margin: 0 10%">
      <el-col :span="8">
        <img
          :src="images[5]"
          style="width: 100%; height: 100%; align-items: center"
        />
      </el-col>
      <el-col :span="16">
        <Titlestrlist :datalist="datalist4.slice(1, 4)"></Titlestrlist>
      </el-col>
    </el-row>

    <Titlestrlist :datalist="datalist5"></Titlestrlist>
    <div style="margin: 0 10%">
      <img
          :src="images[6]"
          style="width: 100%; height: 100%; align-items: center"
        />
    </div>

     <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
      :title="`应用场景`"
      :en_title="`Context of Use`"
    ></Paragraph>
    <Titlestrlist :datalist="datalist6"></Titlestrlist>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Titlestrlist,
  },
  data() {
    return {
      title: '华为BCS服务',
      body:
        '区块链服务（Blockchain Service）是面向企业及开发者的高性能、'
        + '高可用和高安全的区块链技术平台服务，可以帮助企业和开发人员在华为云上快速、低成本的创建、部署和管理区块链应用。',
      image: require('@/assets/image/chain/baas/huawei/huawei.jpg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/baas/huawei/1.jpg'),
        require('@/assets/image/chain/baas/huawei/2.jpg'),
        require('@/assets/image/chain/baas/huawei/3.jpg'),
        require('@/assets/image/chain/baas/huawei/baas1.png'),
        require('@/assets/image/chain/baas/huawei/baas2.png'),
        require('@/assets/image/chain/baas/huawei/baas3.png'),
        require('@/assets/image/chain/baas/huawei/baas4.png'),
      ],
      datalist1: [
        {
          title: '开放易用',
          content:
            '底层技术自主创新、安全可控，基于Kubernetes搭建，配置简单，数分钟内即可完成区块链服务部署，并提供全流程、多维度的自动化运维服务。',
        },
        {
          title: '高性价比',
          content:
            '区块链业务一键上链，节约开发、部署成本；按需付费，减少使用成本；统一运维，节约监控和运维成本；根据用户需求进行弹性伸缩，升级回滚。',
        },
        {
          title: '灵活高效',
          content:
            '区块链服务支持多种高效共识算法，切换灵活，秒级共识（10,000+TPS），多角色节点和成员可动态加入/退出，采用容器化物理资源管理。',
        },
        {
          title: '安全隐私保护',
          content:
            '区块链服务拥有完善的用户、秘钥、权限管理和隔离处理，多层加密保障，国密和同态加密等隐私处理，可靠的网络安全基础能力，运营安全无忧。',
        },
      ],
      datalist2: [
        {
          title: '供应链金融',
          content:
            '区块链技术的去中心化、不可篡改、共享账本等特征使业务信息公开透明公正，有助于小企业提高信用，增加融资概率，降低融资成本。同时，区块链技术也使得合同、物流、仓单等数据不可篡改，可有效降低风控成本',
        },
        {
          title: '效率高',
          content:
            '区块链技术的不可篡改特性，使合同、物流、仓单可作为链上各方统一认可凭证，极大提高融资效率',
        },
        {
          title: '成本低',
          content:
            '区块链技术使得物流、信息流、资金流被完整记录和跟踪，降低风控和融资成本',
        },
        {
          title: '信用高',
          content:
            '区块链技术使得业务信息公正化、透明化，有助于供应链中小企业提高信用，获得优惠利率的贷款。',
        },
      ],
      datalist3: [
        {
          title: '供应链溯源',
          content:
            '共享账本由参与方共同维护且不可篡改，确保数据实时、有序、真实不可伪造。各方通过商品唯一码可进行溯源，确保商品及流通过程的真实性。',
        },
        {
          title: '不可篡改',
          content: '商品特性和流通信息以加密方式记录到区块链中，防止数据篡改。',
        },
        {
          title: '过程透明',
          content:
            '商品在供应链中的权属关系和流通过程通过一物一码的方式完整透明的记录在区块链中。',
        },
        {
          title: '追溯简单',
          content:
            '区块链技术保证数据不可篡改、流通过程透明，使参与方能真实了解其上下游和商品流通过程，监管部门可以实时准确监管。',
        },
      ],
      datalist4: [
        {
          title: '数字资产',
          content:
            '大数据交易、资产交易、共享经济、积分流通等场景可通过区块链实现价值流通，自动进行资产确权、交易确认、记账对账和清算，可有效提高流通效率，规避内部作弊的风险。',
        },
        {
          title: '减少纠纷',
          content:
            '区块链技术使资产加了不可篡改的时间戳，实现资产登记即公示，方便资产权限、时效追踪，有效减少资产纠纷。',
        },
        {
          title: '提高效率',
          content:
            '区块链技术的信任、共享和安全隐私等特性使数字资产能多方可信流通，极大提高资产利用率和流通效率。',
        },
        {
          title: '规避作弊',
          content:
            '区块链技术的防篡改能力，能有效防止数据篡改，规避内部作弊风险。',
        },
      ],
      datalist5: [
        {
          title: '众筹公证',
          content:
            '区块链服务为互助保险、大病众筹及公益捐赠等以公信力为核心的领域解决机构间互信问题，实现资金流向可追溯，信息公开透明并全网共享，规避了内部作弊风险，提高公信力。',
        },
        {
          title: '提高效率',
          content:
            '区块链服务使信息共享更加快速，打破人与人、机构与机构间的壁垒，通过智能合约各环节可以自动执行。',
        },
        {
          title: '精准控制',
          content:
            '区块链技术促进资金流通，并精准控制资金流动的去向，使用范围，甚至时间有效期。',
        },
        {
          title: '提高公信力',
          content:
            '区块链节点间从人共识到算法共识，更加公开公平，相互信任，不可篡改，不可抵赖。',
        },
      ],
      datalist6: [
        {
          title: '快速部署',
          content:
            '相对于自建区块链，部署时间从天级降至分钟级。',
        },
        {
          title: '成员动态加入',
          content:
            '通过邀请华为云租户组建联盟区块链，各成员节点运行在独立的VPC，独立管理，安全可控。',
        },
        {
          title: '共识算法可插拔',
          content:
            '区块链服务提供SOLO、Kafka、Raft排序和FBFT等多种算法以适应不同场景。',
        },
        {
          title: '节点弹性伸缩',
          content:
            '根据用户高可用要求，无需重启系统，支持peer和orderer节点弹性伸缩。',
        },
        {
          title: '可视化链代码管理',
          content:
            '区块链服务提供可视化界面，可方便管理链代码的全生命周期：链代码查看、链代码安装和链代码实例化。',
        },
        {
          title: '云端监控',
          content:
            '主动升级底层区块链平台，提供开放性云监控服务平台，对数据和资源实时监控、告警、通知。',
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
